<template>
  <div>

  <!-- 表格筛选位置 -->
<!-- TODO: 需要接口请求参数-->
  <el-row class="filter" type="flex" justify="space-between">
    <el-col :span="6">
      <span>筛选条件:</span>
      <el-select 
      popper-class="select"
      v-model="page.agentType" 
     placeholder="按代理产品类型">
    <el-option
       v-for="item in type"
:key="item.id"
:label=item.type
:value="item.type"
     >
    </el-option>
  </el-select>
  <button class="btnFilter" @click="searchType">筛选一下</button>
       </el-col>
    <el-col :span="2" class="ipt">
   <el-input   placeholder="请输入公司名称/姓名" v-model="page.keyWord"></el-input>
    </el-col>
     <el-col :span="4"> <button class="updateBtn" @click="searchName">搜索一下</button></el-col>
  </el-row>
  <div class="box-card">
    <el-table :data="list" >
      <el-table-column width="200" prop="agentType" align="center" label="申请代理产品类型">
        <template slot-scope="{row}">
         <span>{{row.agentType===0? '全部':'全部'}}</span>
        </template>
      </el-table-column>
      <el-table-column width="230" prop="company" label="公司名称" align="center"></el-table-column>
      <el-table-column width="230" prop="agentName" label="姓名" align="center">
       
      </el-table-column>
      <el-table-column width="230" prop="phone" label="电话" align="center">
        
      </el-table-column>
      <el-table-column width="230" prop="position"  label="职位" align="center">
        
      </el-table-column>
      <el-table-column width="200" prop="creteTime" label="申请时间" align="center">        
      </el-table-column>
      <el-table-column width="250" label="操作" align="center">
        <template slot-scope="{row}"><el-button @click="getDetail(row.id)">查看</el-button> </template>
        
      </el-table-column>
    </el-table>
  </div>
<!-- 查看的弹窗 -->
<el-dialog title="代理商申请" :visible.sync="showDialog">
  <el-form label-width="420px" label-position="right">

 <el-form-item label="代理产品类型：">
  <el-input class="dialog" v-model="apply.agentType" ></el-input>
 </el-form-item>
  <el-form-item label="公司名称：">
  <el-input class="dialog" v-model="apply.company" ></el-input>
 </el-form-item>
  <el-form-item label="公司地址：">
  <el-input class="dialog" v-model="apply.comAddress" ></el-input>
 </el-form-item>
  <el-form-item label="姓名：">
  <el-input class="dialog" v-model="apply.agentName" ></el-input>
 </el-form-item>
  <el-form-item label="电话：">
  <el-input class="dialog" v-model="apply.phone" ></el-input>
 </el-form-item>
  <el-form-item label="职位：">
  <el-input class="dialog" v-model="apply.position" ></el-input>
 </el-form-item>

  <el-form-item label="邮箱：">
  <el-input class="dialog" v-model="apply.email" ></el-input>
 </el-form-item>
  <el-form-item class="btnitem">
    <el-button class="clipbtn" @click="clipApply">复制信息</el-button>
    <el-button class="outbtn" @click="outbtn">退出</el-button>
  </el-form-item>

  </el-form>

</el-dialog>
  </div>
</template>

<script>
import {time1} from '@/main.js'

import {getCompany,getDetail,searchAgent,getAgentType} from  '@/api/application.js'
export default {
data() {
  return {
    list:[],
    page:{
      pageNum:'1',
      pageSize:'50',
      keyWord:'',
      agentType:''
    },
    count:null,
    showDialog:false,
    apply:{
      agentType:'',//代理商产品类型
      company:'',//公司名称
      comAddress:'',//公司地址
      agentName:'',//姓名
      phone:'',//电话
      position:'',//职位
      
      email:'',//邮箱
    },
    capply:{},
    type:[]
  }

},
created () {
  this.getCompany()

  this.getAgentType()
},
methods: {
  //获取代理商列表
async getCompany(){
let {data:{obj,count}}=await getCompany(this.page)
 obj.forEach(item=>item.creteTime=
   time1(item.creteTime)

     )
this.list=obj
this.count=count
},
//获取代理商的类型
async getAgentType(){
let {data}=await getAgentType()
this.type=data
},
//按代理商类型筛选
  async searchType(){
 await getCompany(this.page)
 this.page.agentType=''
  },

//按公司名称筛选
async searchName(){

 let {data,msg}=  await searchAgent(this.page)
 if(msg==='暂无结果！'){
    this.list=[]
       this.$message.error('没有您要查询的内容')
 }else{
 let {data:{obj},msg}=  await searchAgent(this.page)

  obj.forEach(item=>item.creteTime=
   time1(item.creteTime)

     )
 this.list=obj
 }
   
   this.page.keyWord=''
  
},
 //查看代理商详情
  async getDetail(id){
    let {data} = await getDetail({id:id+''})
    this.showDialog=true
    this.apply.agentName=data.agentName
    this.apply.agentType=data.agentTypeagentType===0? '全部':'全部'
    this.apply.company=data.company
    this.apply.email=data.email
    this.apply.comAddress=data.comAddress
    this.apply.position=data.position
    this.apply.phone=data.phone

   },
   //复制代理商信息
   clipApply(){
      let showDialog={
     agentType:'代理商产品类型',
      company:'公司名称',
      comAddress:'公司地址',
      agentName:'姓名',
      phone:'电话',
      position:'职位',
      email:'邮箱'
  }
  
     var _input = document.createElement('input')
    for(let key in showDialog){
     this.capply[showDialog[key]] =this.apply[key]
    }
     _input.value = JSON.stringify(this.capply).slice(1,-1)
     document.body.appendChild(_input)
     _input.select()
     document.execCommand('Copy')
     this.$message.success('复制成功')
     _input.remove()
     this.showDialog=false
    },
    //点击退出
outbtn(){
  this.showDialog=false
}
   }
}

</script>

<style lang="less" scoped>
// 头部样式
 .el-col{
    font-size: 12px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #4A71AF;
  }

//头部筛选样式
.filter{
  margin-top: 43px;
  span{
    font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #273352;
  }
  .el-select{
margin-right: 20px;

  }
 /deep/ .el-input {
.el-input__inner{
    width: 155px;
height: 40px;
background: #FFFFFF;
border-radius: 12px;
border: 1px solid #C9C9C9;
  }

 }
 .ipt {
  margin-left: 650px;
  /deep/ .el-input .el-input__inner{
width: 280px;
height: 40px;
background: #FFFFFF;
border-radius: 12px;
border: 1px solid #C9C9C9;
 }}
 .btnFilter{
  width: 110px;
height: 40px;
background: #4A71AF;
border-radius: 12px;
font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #FFFFFF;
border: none;
 }
 .updateBtn{
  width: 138px;
height: 40px;
background: #4A71AF;
border-radius: 12px;
font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #FFFFFF;
border: none;
margin-right: 60px;
 }
}
//表格样式
.box-card{
  margin-top: 40px;
 
}


.el-table{
margin-left: 20px;
margin-right: 90px;
}
/deep/.el-table__header-wrapper{
width: 1610px;
height: 60px;
line-height:35px;
background: #F2F2F2;
border-radius: 20px 20px 0px 0px;

th.el-table__cell{
background: #F2F2F2;
font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #000000;
}
}
 .el-table::before{
  height: 0;
} 
/deep/ .el-button{
  width: 68px;
height: 28px;
background: #4A71AF;
border-radius: 8px;

font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 1px;
}
.dialog /deep/.el-input__inner{
  height: 20px;
border: 0;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #4A71AF;
box-sizing: border-box;
margin-top: -20px;
}
/deep/ .el-form-item__content{
  line-height:20px;
}
/deep/ .el-dialog{
  width: 966px;
height: 520px;
background: #FFFFFF;
border-radius: 51px;
padding-top: 20px;
}
/deep/ .el-dialog__title{
width: 95px;
height: 22px;
font-size: 19px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #273352;
line-height: 22px;

}

/deep/ .el-form-item__label{
  width: 105px;
height: 20px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #273352;
line-height: 20px;
letter-spacing: 1px;
}
/deep/ .el-dialog__close{
font-size: 20px;
color: #333303;
opacity: 0.4;
margin-top: 23px;
margin-right: 20px;
}
.btnitem&.el-form-item{
  margin-top: 55px;
  padding-right: 200px;
box-sizing: border-box;

}
.clipbtn&.el-button{
  width: 110px;
height: 40px;
background: #4A71AF;
border-radius: 12px;

font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #FFFFFF;
line-height: 15px;
margin-right: 40px;
margin-left: -200px;
box-sizing: border-box;
}
.outbtn&.el-button{
box-sizing: border-box;

width: 110px;
height: 40px;
background: #FFFFFF;
border-radius: 12px;
border: 2px solid #4A71AF;
font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #4A71AF;
line-height: 15px;

}

</style>