import request from "../utils/request";

//获取代理商列表
export const getCompany=data=>{
  return request({
    method:'POST',
    url:'/system/agent/getAgentList',
    data
  
  })
  }

  //查看代理商详情
  export const getDetail=data=>{
    return request({
      method:'POST',
      url:'/system/agent/seeAgent',
      data
    
    })
    }
  
  //搜索代理商
  export const searchAgent=data=>{
    return request({
      method:'POST',
      url:'/system/agent/serchAgent',
      data,
    })
    }
    //获取代理商类型
    
    export const getAgentType=()=>{
      return request({
        method:'POST',
        url:'/system/agent/getType'
      })
      }

